.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #0075A3;
  font-size: 14px;
  line-height: 1.42857143;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  color: #fff !important;
  padding: 10px 20px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border-radius: 100px;
}
.button:hover,
.button:focus {
  color: #fff !important;
  background-color: #FFC400;
}
.button:active {
  background-color: #e6b000;
}
.button-invert {
  background-color: #FFC400;
  color: #0075A3 !important;
}
.button-invert:hover,
.button-invert:focus {
  background-color: #0075A3;
}
.button-invert:active {
  background-color: #00638A;
}
.button-red {
  background-color: #d23040;
  color: #fff !important;
}
.button-red:hover,
.button-red:focus {
  background-color: #0075A3;
}
.button-red:active {
  background-color: #00638A;
}
.cb-home {
  height: 48px;
  margin: 32px 0 32px 5%;
}
.whitehome {
  top: 32px;
  height: 48px;
}
.cb-pages {
  margin-top: 112px;
}
@media (max-width: 1023px) {
  .footarea {
    justify-content: flex-start;
  }
  .footpart {
    margin-top: 50px;
  }
  .footpart.footpart--2 {
    width: calc(100% - 480px);
    align-items: flex-end;
    justify-content: flex-start;
  }
  .footpart.footpart--3,
  .footpart.footpart--4 {
    width: auto;
  }
  .footnavi div.sub1,
  .vcard {
    min-width: 240px;
    margin-left: 0;
  }
  #legal .meta.service_artos {
    margin-right: 0;
  }
  #legal .meta.service_datenschutz {
    margin-right: 30px;
  }
}
@media (max-width: 1023px) {
  .farnorth {
    font-size: 13px;
    line-height: 1.38461538;
  }
  .farnorth strong {
    font-size: 16px;
    line-height: 1.5;
  }
}
.main {
  width: 100%;
}
.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.main .cb-album .body,
.main .head,
.main .foot,
.main .part {
  margin-right: 2.32198142%;
  margin-left: 2.32198142%;
  width: 95.35603715%;
}
.main .tiny {
  width: 45.35603715%;
}
.main > .slim {
  width: 50%;
}
.main > .slim .head,
.main > .slim .foot,
.main > .slim .part {
  margin-right: 4.64396285%;
  margin-left: 4.64396285%;
  width: 90.7120743%;
}
.main > .slim.cb-album .body {
  margin-right: 4.64396285%;
  margin-left: 4.64396285%;
  width: 90.7120743%;
}
.main > .slim .tiny {
  width: 90.7120743%;
}
.cb-page-layout2 .main {
  width: 100%;
}
.cb-page-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-page-layout2 .main .cb-album .body,
.cb-page-layout2 .main .head,
.cb-page-layout2 .main .foot,
.cb-page-layout2 .main .part {
  margin-right: 1.54004107%;
  margin-left: 1.54004107%;
  width: 96.91991786%;
}
.cb-page-layout2 .main .tiny {
  width: 46.91991786%;
}
.cb-page-layout2 .main > .slim {
  width: 50%;
}
.cb-page-layout2 .main > .slim .head,
.cb-page-layout2 .main > .slim .foot,
.cb-page-layout2 .main > .slim .part {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.cb-page-layout2 .main > .slim.cb-album .body {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.cb-page-layout2 .main > .slim .tiny {
  width: 93.83983573%;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .cb-album .body,
.side .head,
.side .foot,
.side .part {
  margin-right: 5.63909774%;
  margin-left: 5.63909774%;
  width: 88.72180451%;
}
.side .tiny {
  width: 38.72180451%;
}
.side > .slim {
  width: 50%;
}
.side > .slim .head,
.side > .slim .foot,
.side > .slim .part {
  margin-right: 11.27819549%;
  margin-left: 11.27819549%;
  width: 77.44360902%;
}
.side > .slim.cb-album .body {
  margin-right: 11.27819549%;
  margin-left: 11.27819549%;
  width: 77.44360902%;
}
.side > .slim .tiny {
  width: 77.44360902%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base .cb-album .body,
.base .head,
.base .foot,
.base .part {
  margin-right: 2.98804781%;
  margin-left: 2.98804781%;
  width: 94.02390438%;
}
.base .tiny {
  width: 27.35723772%;
}
.base > .slim {
  width: 33.33333333%;
}
.base > .slim .head,
.base > .slim .foot,
.base > .slim .part {
  margin-right: 8.96414343%;
  margin-left: 8.96414343%;
  width: 82.07171315%;
}
.base > .slim.cb-album .body {
  margin-right: 8.96414343%;
  margin-left: 8.96414343%;
  width: 82.07171315%;
}
.base > .slim .tiny {
  width: 82.07171315%;
}
.farwest {
  width: 100%;
}
.farwest > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farwest .cb-album .body,
.farwest .head,
.farwest .foot,
.farwest .part {
  margin-right: 1.54004107%;
  margin-left: 1.54004107%;
  width: 96.91991786%;
}
.farwest .tiny {
  width: 46.91991786%;
}
.farwest > .slim {
  width: 50%;
}
.farwest > .slim .head,
.farwest > .slim .foot,
.farwest > .slim .part {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.farwest > .slim.cb-album .body {
  margin-right: 3.08008214%;
  margin-left: 3.08008214%;
  width: 93.83983573%;
}
.farwest > .slim .tiny {
  width: 93.83983573%;
}
.main {
  width: 104.87012987%;
  margin-left: -2.43506494%;
}
.cb-page-layout2 .main {
  width: 103.1779661%;
  margin-left: -1.58898305%;
}
.side {
  width: 112.71186441%;
  margin-left: -6.3559322%;
}
.base {
  width: 106.3559322%;
  margin-left: -3.1779661%;
}
.farwest {
  width: 103.1779661%;
  margin-left: -1.58898305%;
}
.side .seam,
.base .seam {
  box-sizing: border-box;
  margin-top: 30px;
  margin-bottom: 30px;
}
.side .seam .body,
.base .seam .body {
  margin-top: -15px;
  margin-bottom: -15px;
}
.side .seam {
  border-left: 2px solid #fff;
}
.base .seam {
  border-left: 2px solid #E5E5E5;
}
.cb-page-layout3 .main .seam {
  margin-left: 2.32198142%;
  margin-right: 2.32198142%;
  width: 95.35603715%;
}
.cb-page-layout3 .main .seam.slim {
  width: 45.35603715%;
}
/*# sourceMappingURL=./screen-medium.css.map */